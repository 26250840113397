// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

//**** FIREBASE ****///
import * as firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';
firebase.initializeApp({
  apiKey: "AIzaSyCFr9gvCBaKd-7VbRiljFT-w7W4_Wl2lUw",
 // authDomain: "corona-chile.firebaseapp.com",
  authDomain: "coronachile.com",
  databaseURL: "https://corona-chile.firebaseio.com",
  projectId: "corona-chile",
  storageBucket: "corona-chile.appspot.com",
  messagingSenderId: "793571400263",
  appId: "1:793571400263:web:30a8ef32249afacfe9ef62",
  measurementId: "G-SHSCJ36CJW"
});

const defaultAnalytics = firebase.analytics();
Vue.prototype.$firebase = firebase;

//**** Moment ****///
import moment from 'moment';
moment.locale('es');
Vue.prototype.$moment = moment;
//**** Store ****///
import { store } from '../store/store';
// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),
  store,
  // Register App Component
  components: {
    app: App
  },
});