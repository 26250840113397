import Vuex from 'vuex'
import Vue from 'vue'
import moment from 'moment';
moment.locale('es');
Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        appName:'ccPanel',
        appUrl: 'https://panel.coronachile.com',
        appVersion: '0.1.5 BETA',
        activeTab:'/',
        profile_userInfo: {},
        misprivx:{},
        mainCoreData:[],
        fechafiltro:'rango',
        rangoDates: null,
        byday: null
    },
    getters: {
        myAvatar: state => {
                return (state.profile_userInfo && state.profile_userInfo.photoURL) ? state.profile_userInfo.photoURL : state.profile_defaultAvatar;
        },
        myName: state=>{
            return state.profile_userInfo.displayName || 'Indique su nombre';
        },
        filteredData: state=>{
            return state.mainCoreData.filter(undat=>{
                let incluid=false;
                let unlog=undat.log;
                if(state.fechafiltro=='rango'){
                    if(state.rangoDates){
                        let fechs=state.rangoDates.split(' - ');
                        if(!fechs[1] || fechs[0]==fechs[1]){
                            incluid= moment(fechs[0], "DD/MM/YYYY").isSame(unlog.w, 'day');
                        }
                        else{
                            incluid= moment(fechs[0], "DD/MM/YYYY").isSameOrBefore(unlog.w, 'day') && moment(fechs[1], "DD/MM/YYYY").isSameOrAfter(unlog.w, 'day');
                        }
                      }
                      else{
                        incluid= true;
                      }
                }
                else{
                    let hoystrg=state.byday || moment().format( "DD/MM/YYYY");
                    incluid= moment(hoystrg, "DD/MM/YYYY").isSame(unlog.w, 'day');
                }
              return incluid;
            });
          },
    },
    mutations: {
  
        setWhatTo: (state,payload)=>{
            state[payload.what] = payload.to;
        },
        mergeObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},state[payload.what],payload.to);
        },
        setObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},payload.to);
        },
        pushToArray: (state,payload) => {
            state[payload.array].push(payload.value);
        },
        conactToArray: (state,payload) => {
          let concatenado = state[payload.array].concat(payload.value);
          state[payload.array] = concatenado;
      },
        removeFromArray: (state,payload)=>{
            let indiexist = state[payload.array].findIndex(unitem => unitem[payload.attr]==payload.value);
            if(indiexist!=-1){
                state[payload.array].splice(indiexist,1);
            }
        },
        updateMyAvatar: (state,avatarURL)=>{
            state.profile_userInfo.photoURL=avatarURL;
        },
        //Editar array
        editArray(state, payload){
            const index = state[payload.array].findIndex((item) => item[payload.attr] === payload.value);
            if(index !== -1){
                state[payload.array].splice(index, 1, payload.updatedValue);
            }
        }
    },
    actions:{

    }
})