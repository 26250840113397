<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false" title="CoronaChile" subtitle="Dashboard" >
      <f7-nav-right>
        <f7-link icon-material="menu" panel-open="right"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- Toolbar-->
    <f7-toolbar bottom>
       <f7-link>{{profile_userInfo.email}}</f7-link>
      <f7-link>ccPanel {{appVersion}} by HICAPPS</f7-link>
      <f7-block><f7-button outline @click="logout()">Cerrar Sesión</f7-button></f7-block>
    </f7-toolbar>
    <!-- Page content-->
 <f7-row class="altofull">
    <f7-col class="altofull">
      <div class="map"></div>
      </f7-col>
    <f7-col>
      <div class="fwidth">
      <bar-chart :chart-data="datacollection"></bar-chart>
    </div>
     <div class="fwidth" v-show="fechafiltro === 'rango'">
       <line-chart :chart-data="lineDatacollection"></line-chart>
    </div>

    </f7-col>
    <f7-col>
          <f7-list class="no-margin">
      <f7-list-item divider title="Sintomas de Formulario 2"></f7-list-item>
      <f7-list-item
      v-for="unsint in edades.sintomsOrde" :key="unsint.id"
      :title="unsint.nam"
      :after="unsint.n||'0'"
      ></f7-list-item>
      <f7-block-footer>
    <p>En base a <b>{{edades.nf2s}}</b> f2 completos</p>
  </f7-block-footer>
    </f7-list>
  
    </f7-col>
    <f7-col class="fgris">
      <f7-list class="no-margin">
        <f7-list-item title="Casos Notificados" :after="filteredData.length||'0'"></f7-list-item>
        <f7-list-item title="Trabajadores Salud" :after="edades.trbsjsalud||'0'"></f7-list-item>
        <f7-list-item title="Embarazos" :footer="edades.porcembarz+'% de las mujeres'" :after="edades.embrs||'0'"></f7-list-item>
      </f7-list>
       <f7-block-title>Filtros</f7-block-title>
        <f7-list class="fgris">
        <f7-list-item 
        radio 
        class="fgris"
        title="Rango de Fechas" 
        name="fechafiltro" 
        value="rango"
        :checked="fechafiltro === 'rango'"
        @change="$store.commit('setWhatTo',{what:'fechafiltro',to: $event.target.value})"
        ></f7-list-item>
          <f7-list-input
          class="fgris"
          v-show="fechafiltro === 'rango'"
            label="Rango de Fechas"
            type="datepicker"
            placeholder="Todas"
            readonly
             @change="$store.commit('setWhatTo',{what:'rangoDates',to: $event.target.value})"
            :calendar-params="{ dateFormat: 'dd/mm/yyyy', maxDate: new Date(), minDate:new Date(2020,2,22) , value:[new Date(2020,2,22),new Date()], rangePicker: true ,closeOnSelect: true }"
          ></f7-list-input>
        <f7-list-item 
        class="fgris"
        radio 
        title="Por día" 
        name="fechafiltro" 
        value="byday"
        :checked="fechafiltro === 'byday'"
        @change="$store.commit('setWhatTo',{what:'fechafiltro',to: $event.target.value})"
        ></f7-list-item>
         <f7-list-input
         class="fgris"
          v-show="fechafiltro === 'byday'"
            label="Fecha"
            type="datepicker"
            placeholder="hoy"
            ref="fechaone"
            readonly
             @change="$store.commit('setWhatTo',{what:'byday',to: $event.target.value})"
            :calendar-params="{ dateFormat: 'dd/mm/yyyy', value:[new Date()], maxDate: new Date(), minDate:new Date(2020,2,22) ,closeOnSelect: true }"
          ></f7-list-input>
      </f7-list>
      <f7-block  v-show="fechafiltro === 'byday'" class="text-align-center">
        <f7-row>
        <f7-col><f7-button @click="cambiafech(false)" outline icon-material="chevron_left"></f7-button></f7-col>
        <f7-col></f7-col>
        <f7-col><f7-button @click="cambiafech(true)" outline icon-material="chevron_right"></f7-button></f7-col>
      </f7-row>
      </f7-block>
    
    </f7-col>
  </f7-row>



  </f7-page>
</template>

<script>

import {mapState, mapGetters} from 'vuex';
import BarChart from '../components/BarChart.js'
import LineChart from '../components/LineChart.js'


export default {
   components: {
      BarChart,LineChart
    },
  data(){
    return {
   
      datacollection: {
        labels:['>90','80-90','70-80','60-70','50-60','40-50','30-40','20-30','10-20','0-10'],
        datasets:[]
      },
      lineDatacollection:{
        labels:[],
        datasets:[]
      },
      fechsLine:{},
      proLinelabels:[]
    }
  },
  computed:{
   ...mapState(['profile_userInfo','appVersion','datum','mainCoreData','rangoDates','mapPoints','fechafiltro','byday']),
   ...mapGetters(['filteredData']),

   edades(){
     this.resetCountersLines();
     let ahora=this.$moment();
      let sumages=0;
      let arregls={
        'Masculino': [0,0,0,0,0,0,0,0,0,0],
        'Femenino': [0,0,0,0,0,0,0,0,0,0]
      };
      let sintomas={
        fiebresobretroch:0,
        cefalea:0,
        doltoracico:0,
        dolorgargnt:0,
        tos:0,
        difresp:0,
        taquipnea:0,
        cianosis:0,
        mialgia:0,
        dolorabdominal:0,
        diarrea:0,
        postracion:0
      };
      let sintomax=[
        {n:0,nam:'Fiebre',
            id: 'fiebresobretroch'},
            {n:0,nam:'Cefalea',
            id: 'cefalea'},
            {n:0,nam:'Dolor Torácico',
            id: 'doltoracico'},
            {n:0,nam:'Odinofagia',
            id: 'dolorgargnt'},
            {n:0,nam:'Tos',
            id: 'tos'},
            {n:0,nam:'Disnea',
            id: 'difresp'},
            {n:0,nam:'Taquipnea',
            id: 'taquipnea'},
            {n:0,nam:'Cianosis',
            id: 'cianosis'},
            {n:0,nam:'Mialgia',
            id: 'mialgia'},
            {n:0,nam:'Dolor Abdominal',
            id: 'dolorabdominal'},
            {n:0,nam:'Postración',
            id: 'postracion'},
            {n:0,nam:'Diarrea',
            id: 'diarrea'}];
      let nf2s=0;
      let masc=[0,0,0,0,0,0,0,0,0,0];
      let fem=[0,0,0,0,0,0,0,0,0,0];
      let mujrs=0;
      let embrs=0;
      let trbsjsalud=0;
     this.filteredData.forEach(unmegadat => {
       //log data
       let logdata=unmegadat.log || {};
       let ahorsx=this.$moment(logdata.w).format('DD-MM-YYYY');
       this.fechsLine[ahorsx]++;
       let form=unmegadat.frm || {};
       let fecjnax=form.fechanac;
       let sexo=form.sexo;
       if(fecjnax && sexo && arregls[sexo]){
         let ageinanos=ahora.diff(this.$moment(fecjnax,'DD-MM-YYYY'), 'years');
          if(ageinanos>90){
            arregls[sexo][0]++;
          }
          else if (ageinanos>=80){
            arregls[sexo][1]++;
          }
           else if (ageinanos>=70){
            arregls[sexo][2]++;
          }
           else if (ageinanos>=60){
            arregls[sexo][3]++;
          }
           else if (ageinanos>=50){
            arregls[sexo][4]++;
          }
           else if (ageinanos>=40){
            arregls[sexo][5]++;
          }
           else if (ageinanos>=30){
            arregls[sexo][6]++;
          }
           else if (ageinanos>=20){
            arregls[sexo][7]++;
          }
           else if (ageinanos>=10){
            arregls[sexo][8]++;
          }
           else {
            arregls[sexo][9]++;
          }
        // sumages+=ageinanos;
       }
       if(sexo=='Femenino'){
         mujrs++;
         if(form.embarazo){
           embrs++;
         }
       }
       if(form.estrabajadorsalud){
         trbsjsalud++;
       }
       if(form.f2){
         let sintms=form.sintomasf2 || [];
         //sintms
         sintms.forEach(unsint => {
           sintomas[unsint]++;
         });
         nf2s++;
       }
     });
     arregls['Masculino'].forEach((unragn,idx) => {
        arregls['Masculino'][idx]=-unragn;
     });
     let porcembarz=mujrs?(embrs*100/mujrs).toFixed(2):0;
     sintomax.forEach((unsintmobje,idx) => {
       sintomax[idx].n=sintomas[unsintmobje.id];
     });
     let sintomsOrde=sintomax.sort((a,b)=>{
       return b.n-a.n;
     });
     console.log(sintomsOrde);
     this.datacollection=Object.assign({},this.datacollection,{datasets:[{
              label: 'Masculino',
              backgroundColor: '#0095ff',
              data: arregls['Masculino'],
              stack: 'a'
            }, {
              label: 'Femenino',
              backgroundColor: '#ff71da',
               data: arregls['Femenino'],
              stack: 'a'
            }]});

    let arregloLine=[];
    let newLabels=[];
    this.proLinelabels.forEach(undiastrg => {
      if(this.fechsLine[undiastrg]){
        newLabels.push(undiastrg);
        arregloLine.push(this.fechsLine[undiastrg]);
      }
    });
    this.lineDatacollection=Object.assign({},this.lineDatacollection,{labels:newLabels,datasets:[{label:'Reportes/dia',data:arregloLine}]});



     return {porcembarz,embrs,trbsjsalud,nf2s,sintomsOrde};
   }
  },
 created(){
   let startDate=this.$moment('2020-03-22');
   let now=this.$moment();
   while(startDate.isSameOrBefore(now,'day')){
     let enstirgin=startDate.format('DD-MM-YYYY');
     this.proLinelabels.push(enstirgin);
     this.fechsLine[enstirgin]=0;
     startDate.add(1, 'd');
   }
   console.log('labels',this.proLinelabels);
 },
  methods:{
        cambiafech(alfuturo){
        console.log(alfuturo);
        let calendas=this.$refs.fechaone;
        console.log(calendas.f7Calendar);
        let hoystrg=this.byday || this.$moment().format( "DD/MM/YYYY");
        let mometeado = this.$moment(hoystrg, "DD/MM/YYYY");
        if(alfuturo){
           calendas.f7Calendar.setValue([mometeado.add(1, 'd').toDate()]);
        }
        else{
           calendas.f7Calendar.setValue([mometeado.subtract(1, 'd').toDate()]);
        }
      },
 
      resetCountersLines(){
        this.proLinelabels.forEach(diastring => {
          this.fechsLine[diastring]=0;
        });
      },
      logout() {
        console.log('Logging out');
        this.$f7.dialog.preloader('Cerrando sesión...');
        this.$firebase.auth().signOut().then(()=> {
          console.log('Sign-out successful.');
          this.$store.commit('setObjTo',{what:'misprivx',to:{}});
           this.$store.commit('setObjTo',{what:'profile_userInfo',to:{}});
          this.$f7.dialog.close();
          if(conexplicacion){
            this.$f7.dialog.alert('No disponible para usuarios anonimos');
          }
        }).catch((error)=> {
          this.$f7.dialog.close();
          console.log('Sign-out error.', error);
        });
      },

  }
}
</script>
<style >
.map{
  width:100%;
  height: 100%;
}
.altofull{
  height:100%!important;
}
.fwidth{
  width:100%;
}



.cuantos{
  font-size:40px;
  font-weight: bold;
}
.fgris{
  background: #f3f3f3;
}
.fgris .item-input-wrap{
  background:#FFF!important;
}
</style>