<template>
<f7-app :params="f7params" >




  <!-- Right panel with reveal effect-->
  <f7-panel right cover theme-dark :visible-breakpoint="960">
    <f7-view>
      <f7-page>
        <f7-navbar title="Log">
    <f7-nav-right>
      <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
    </f7-nav-right>
    <f7-searchbar
      class="searchbar-demo"
      expandable
      search-container=".padfix"
      search-item="li"
      placeholder="Buscador"
      search-in=".item-title"
    ></f7-searchbar>
  </f7-navbar>
          <f7-list class="searchbar-not-found">
      <f7-list-item title="Sin resultados"></f7-list-item>
    </f7-list>
       <f7-list
       class="searchbar-found no-margin-top no-margin-bottom altomaximo padfix" 
       media-list
       virtual-list
      :virtual-list-params="{ items, searchAll ,renderExternal, height: 48}"
      ref="hospitals"
       >
       <ul>
          <f7-list-item
          v-for="(prelog,idx) in vlData.items"
          :key="idx"
          :header="prelog.log.hr+' '+prelog.log.i.comuna"
          :title="prelog.log.i.institucion"
          :style="`top: ${vlData.topPosition}px`"
          ></f7-list-item>
       </ul>
        </f7-list>
        
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>


  <!-- Popup -->
 

  <f7-login-screen :opened="loginAbierto" id="my-login-screen">
    <f7-view>
      <f7-page login-screen>
        <f7-login-screen-title>CoronaChile Panel</f7-login-screen-title>
          <f7-block><f7-button @click="entraConGoogle()" outline raised>Login con Google</f7-button></f7-block>
        <f7-list>
          <f7-block-footer>
            Coronachile.com<br>Powered by HICAPPS
          </f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
</f7-app>
</template>
<script>

  import routes from '../js/routes.js';
import {mapState,mapGetters} from 'vuex';
import axios from 'axios';
import loadGoogleMapsApi from 'load-google-maps-api';
import localforage from 'localforage';
  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'ccPanel', // App name
          theme: 'md', // Automatic theme detection
          // App routes
          routes: routes,
        },
        loginAbierto: true,
        googleMaps: null,
        observableMain: null,
        instTest:['-M2d5qET4SyJxpd429HD','-M2dFcGTrCFXbzjFVdcy'],
        formas:{},
        primeraCarga: 0,
        markers:{},
        lemapa: null,
        markerIds:[],
        uniqueMarkers:{},
        ultimoKey: null,
        vlData: {
          items: [],
        },
      }
    },
      computed:{
      ...mapState(['fechafiltro','byday']),
     ...mapGetters(['filteredData']),
      items(){
     return this.filteredData.sort((a,b)=>{
       return b.log.w-a.log.w;
     });
   },
  },
   watch:{
    filteredData(neovalx){
      let soloIdsVisibles=neovalx.map(unlogs=>{
        return unlogs.mymarker;
      });
      this.markerIds.forEach(unmarkerid => {
        if(soloIdsVisibles.includes(unmarkerid)){
          this.markers[unmarkerid].setVisible(true);
        }
        else{
          this.markers[unmarkerid].setVisible(false);
        }
      });
    },
     items(newval){
      this.$refs.hospitals.f7VirtualList.replaceAllItems(newval);
    }
  },
    methods: {
       searchAll(query, items) {
        const found = [];
        for (let i = 0; i < items.length; i += 1) {
          if (
            items[i].log.i.institucion && items[i].log.i.institucion.toLowerCase().indexOf(query.toLowerCase()) >= 0 
            || items[i].log.i.comuna && items[i].log.i.comuna.toLowerCase().indexOf(query.toLowerCase()) >= 0 
          || query.trim() === '') found.push(i);
        }
        return found; // return array with mathced indexes
      },
    renderExternal(vl, vlData) {
        this.vlData = vlData;
      },
      makeUniqueMarker(allmeta){
       let map= allmeta.log.map;
       let institucionid = allmeta.log.i.id;
       if(map && institucionid){
                let mymarkerId;
          if(!this.uniqueMarkers[map.lat] || !this.uniqueMarkers[map.lat][map.lng]){
            let lobje={
              position: {lat: map.lat, lng: map.lng},
              map: this.lemapa
            };
          this.markers[institucionid] = new this.googleMaps.Marker(lobje);
          this.markerIds.push(institucionid);
          mymarkerId=institucionid;
          if(!this.uniqueMarkers[map.lat]){
            this.uniqueMarkers[map.lat]={};
          }
          this.uniqueMarkers[map.lat][map.lng]=institucionid;
          }
          else{
            mymarkerId=this.uniqueMarkers[map.lat][map.lng];
          }
          return mymarkerId;
       }
       else{
         return null;
       }
      },
      encenderObservableNuevos(){
        console.log('escuchando desde',this.ultimoKey)
      this.observableMain = this.$firebase.database().ref('supralog').orderByKey().startAt(this.ultimoKey);
        this.observableMain.on('child_added', (data)=> {
          if(data.key!=this.ultimoKey){
              let onelog=data.val();
            if(onelog.f && !this.formas[onelog.f] && !this.instTest.includes(onelog.i.id)){
              onelog.key=data.key;
              onelog.hr=this.$moment(onelog.w).format('HH:mm - DD/MM/YYYY');
              this.formas[onelog.f]={
                id:onelog.f,
                log:onelog
              };
              //seguir
              this.flujoUnico(onelog.f);
            }
            this.toastMe();
          }
        });
      },
      flujoUnico(formaid){
         return this.getAllTheseForms([formaid]).then(()=>{
            return this.mapAllThisForms([formaid]);
         }).then(()=>{
           //crear marker
            let sinfo=this.formas[formaid];
            let elmarkerd=this.makeUniqueMarker(sinfo);
            if(elmarkerd){
              this.formas[formaid].mymarker=elmarkerd;
            }
            console.log('info lista para ',formaid,this.formas[formaid]);
           this.$store.commit('pushToArray',{array:'mainCoreData',value:this.formas[formaid]});
         }).catch(error=>{
           console.log('error retrieve formaunica',formaid,error)
         });
      },
      toastMe(){
        let toastCenter = this.$f7.toast.create({
          text: 'Nuevo paciente',
          position: 'center',
          closeTimeout: 2000,
          destroyOnClose: true
        });
        toastCenter.open();
      },
      cargaInicial(){
        this.$f7.dialog.preloader('Carga datos inicial...');
        this.$firebase.database().ref('supralog').once('value').then(snapx=>{
          snapx.forEach(data => {
            let onelog=data.val();
           if(onelog.f && !this.formas[onelog.f]){
              if(!this.instTest.includes(onelog.i.id)){
                onelog.key=data.key;
                onelog.hr=this.$moment(onelog.w).format('HH:mm - DD/MM/YYYY');
                this.formas[onelog.f]={
                  id:onelog.f,
                  log:onelog
                };
              }
            }
            this.ultimoKey=data.key;
          });
          console.log('ultimo',this.ultimoKey);
          //ahora pedir formasConData
          let formasid=Object.keys(this.formas) || [];
          return this.getAllTheseForms(formasid);
        }).then(()=>{
          console.log('termino solicitud de formas, inicio mapeos');
          let formasid=Object.keys(this.formas) || [];
          return this.mapAllThisForms(formasid);
         }).then(()=>{
          console.log('mapeo finalizado.Crear markers?');
          let formasid=Object.keys(this.formas) || [];
          formasid.forEach(unaformaid => {
            let sinfo=this.formas[unaformaid];
            let elmarkerd=this.makeUniqueMarker(sinfo);
            if(elmarkerd){
              this.formas[unaformaid].mymarker=elmarkerd;
            }
          });
          console.log('markers OK. cargar a maindata arreglo, de una vez',this.formas);
          let arreglado=Object.values(this.formas);
          this.$store.commit('setWhatTo',{what:'mainCoreData',to: arreglado});
           this.$f7.dialog.close();
           this.encenderObservableNuevos();
        }).catch(error=>{
           this.$f7.dialog.close();
          console.log('error solicit inicial',error);
        })
      },
      async mapAllThisForms(arrayOfFormIds){
          //inicio markeo
          let mapeadasToRequest=[];
          let trueGepcpdeReiqest=[];
          arrayOfFormIds.forEach(unaformaid => {
            let formaval=this.formas[unaformaid];
            let loclog=formaval.log;
            if(!loclog.map && loclog.i && !loclog.mapError){
              console.log('sinmap',this.formas[unaformaid]);
              mapeadasToRequest.push(this.mapmeThisInstit(unaformaid,loclog.i));
              trueGepcpdeReiqest.push(unaformaid);
            }
          });
          console.log('solicitando geocoding',trueGepcpdeReiqest.length);
          return Promise.all(mapeadasToRequest).then((multisna)=>{
            let tosaveOnline=[];
            multisna.forEach((unsnap,idxs) => {
              let laformid=trueGepcpdeReiqest[idxs];
                  if(unsnap){
                    //guardar local y en lanube
                     this.formas[laformid].log.map=unsnap;
                     console.log('ruta de guardado',this.formas[laformid].log.key);
                     tosaveOnline.push(this.$firebase.database().ref('supralog/'+this.formas[laformid].log.key+'/map').set(unsnap));
                  }
                  else{
                    //no se pudo geocogid, avisar a firebase tb para no volver a pedir
                    console.log('no se puede geocodear');
                     tosaveOnline.push(this.$firebase.database().ref('supralog/'+this.formas[laformid].log.key+'/mapError').set(true));
                  }
              });
              console.log('guardando en firebase',tosaveOnline.length);
              return Promise.all(tosaveOnline);
            }).then(()=>{
              console.log('finalizada guardado en firebase');
              return true;
          });
      },
         async getAllTheseFormsLegacy(arrayOfFormIds){
        let promexs=[];
        arrayOfFormIds.forEach(formaId => {
          promexs.push(localforage.getItem(formaId));
        });
        console.log('pidiendo a local forage',promexs.length);
        return Promise.all(promexs).then(multisn=>{
          let frbRequests=[];
          multisn.forEach((value,idx) => {
            let lclFormaId=arrayOfFormIds[idx];
            if(value){
              //we got it
              this.formas[lclFormaId].frm=value;
            }
            else{
              //hay que pedir a firebase
              frbRequests.push(this.$firebase.database().ref('forms/'+lclFormaId).once('value'));
            }
          });
          console.log('pidiendo a firebase',frbRequests.length);
          return Promise.all(frbRequests);
        }).then(multisnaps=>{
          let formasAGuardar=[];
          multisnaps.forEach(undbsnap => {
            let fbFormaId=undbsnap.key;
            this.formas[fbFormaId].frm=undbsnap.val();
            formasAGuardar.push(localforage.setItem(fbFormaId, undbsnap.val()));
          });
          console.log('guardando en localforage',formasAGuardar.length);
          return Promise.all(formasAGuardar);
        }).then(multisnapscc=>{
          return true;
        });
      },
      async getAllTheseForms(arrayOfFormIds){
          let cpanelx = this.$firebase.functions().httpsCallable('cpanelx');
          console.log('pidiendo a firebase fx',arrayOfFormIds.length);
          return cpanelx({formas:arrayOfFormIds}).then(ladata=>{
            // console.log('llego data',ladata);
             let formas=ladata.data || {};
             let formasids=Object.keys(formas) || [];
             formasids.forEach(unaformaid => {
               this.formas[unaformaid].frm=formas[unaformaid];
             });
          return true;
        });
      },
    async mapmeThisInstit(formaid,insti){
      let string=insti.institucion?(insti.institucion+','):'';
      if(insti.direccion){
        string+=insti.direccion+',';
      }
       if(insti.comuna){
        string+=insti.comuna+',';
      }
       if(insti.region){
        string+=insti.region;
      }
      if(string!=''){
       return axios.get('https://maps.googleapis.com/maps/api/geocode/json',{
          params: {
            address: string,
            key: 'AIzaSyCFr9gvCBaKd-7VbRiljFT-w7W4_Wl2lUw'
          }
        }).then( (response)=> {
              // handle success
              let ladata=response.data || {};
              let maps=ladata.results || []
              let prim=maps[0];
              if(prim && prim.geometry && prim.geometry.location){
                 console.log(prim);
                return {lat: prim.geometry.location.lat, lng: prim.geometry.location.lng};
              }
              else{
                return false;
              }
            });
      }
      else{
        return false;
      }
    },


       entraConGoogle(){
       let provider = new this.$firebase.auth.GoogleAuthProvider();
         this.$f7.dialog.preloader('Navegando a Auth...');
        this.$firebase.auth().signInWithRedirect(provider);
     },
       logout(conexplicacion=false) {
        console.log('Logging out');
        this.$f7.dialog.preloader('Cerrando sesión...');
        this.$firebase.auth().signOut().then(()=> {
          console.log('Sign-out successful.');
          this.$store.commit('setObjTo',{what:'misprivx',to:{}});
           this.$store.commit('setObjTo',{what:'profile_userInfo',to:{}});
          this.$f7.dialog.close();
          if(conexplicacion){
            this.$f7.dialog.alert('No disponible para usuarios anonimos');
          }
        }).catch((error)=> {
          this.$f7.dialog.close();
          console.log('Sign-out error.', error);
        });
      },
      dibujarMpa(){
         loadGoogleMapsApi({key:'AIzaSyCFr9gvCBaKd-7VbRiljFT-w7W4_Wl2lUw',libraries:['visualization']}).then((googleMaps)=> {
           this.googleMaps=googleMaps;
          this.lemapa= new this.googleMaps.Map(document.querySelector('.map'), {
              center: {
                lat: -33.4346141,
                lng: -70.5145937
              },
              zoom: 5,
              streetViewControl: false
            });

          }).catch((error)=> {
            console.error(error);
          })
      }
    },
    mounted() {
      this.$f7ready((f7) => {

        this.$f7.dialog.preloader('Cargando...');
        // Call F7 APIs here
        this.$firebase.auth().onAuthStateChanged((user)=> {
        if (user) {
           this.dibujarMpa();
          // User is signed in.
         // console.log('USUARIO DETECTADO POR OBSERVABLE');
         //  console.log('welcome',user);
            let profile_userInfo={
              displayName: user.displayName,
              email: user.email,
               emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              phoneNumber: user.phoneNumber,
              uid: user.uid,
              isAnonymous: user.isAnonymous
            };
            this.$store.commit('setObjTo',{what:'profile_userInfo',to:profile_userInfo});
            if(user.isAnonymous){
              this.$f7.dialog.close();
             this.logout(true);
            }
            else{
              let cleanemail=user.email.replace(/\./g,'^');
              this.$firebase.database().ref('ccpanel/'+cleanemail).once('value').then(snpanshot=>{
                if(!snpanshot.exists()){
                   this.$f7.dialog.close();
                    this.logout(true);
                }
                else{
                   this.$store.commit('mergeObjTo',{what:'misprivx',to:snpanshot.val()||{}});
                  this.$f7.dialog.close();
                  this.loginAbierto=false;
                   this.cargaInicial();
                }
              }).catch(error=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message,error.code,()=>{
                   this.logout(true);
                });
              });
            }
        } else {
          // User is signed out.
          this.$store.commit('setObjTo',{what:'profile_userInfo',to:{}});
          console.log('no user logged in');
          this.loginAbierto=true;
          this.$f7.dialog.close();
        }
      });
      });
    }
  }
</script>
<style>
@media only screen and (min-width: 960px) {
.panel-open{
  display: none!important;
}
}
.padfix li .item-inner{
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>  